<template>
  <Page :title="$t('widgets.orderHistory.header.mainText')">
    <div class="c-orderHistory">
      <BannerImageAndTitle
        :background="[
          {
            url: $t('widgets.orderHistory.header.background[0].url'),
            format: $t('widgets.orderHistory.header.background[0].format'),
            altText: $t('widgets.orderHistory.header.background[0].altText'),
          },
          {
            url: $t('widgets.orderHistory.header.background[1].url'),
            format: $t('widgets.orderHistory.header.background[1].format'),
          },
        ]"
        :banner-title="$t('widgets.orderHistory.header.mainText')"
      />
      <SectionContainer
        size="md"
        class="h-pv-20"
      >
        <Typography
          v-text="$t('widgets.orderHistory.contentBody.headText')"
          variant="subtitle1"
        />
        <Typography
          v-text="$t('widgets.orderHistory.contentBody.subText')"
          variant="body1"
          class="h-mb-20"
        />
        <template
          v-if="!orderHistory.length"
        >
          <Typography
            v-text="$t('widgets.dashboard.banner.emptyOrderState')"
            variant="h3"
            class="-fontBook -topGap h-textCenter"
          />
          <BaseButton
            :to="$i18nRoute({name: HOME})"
            :btn-label="$t('widgets.dashboard.banner.orderNow')"
            variant="solid"
            class="h-mv-20 -spaceAuto"
          />
        </template>
        <template v-else>
          <CardOrder
            v-for="order in orderHistory"
            :key="order.id"
            class="h-mt-10 h-cursor-pointer"
            :date="new Date(order.utcDate)"
            :price="convertTotal(order.total, locale)"
            :products="order.products"
            :id="order.id"
            is-history-page
            @showDetails="handleModal(order)"
          />
        </template>
      </SectionContainer>
    </div>
    <section
      class="order-modal"
      v-if="selectedOrder"
    >
      <BaseModal
        size="large"
        :show-modal="showModal"
      >
        <template #body>
          <OrderDetails :selected-order="selectedOrder" />
        </template>
      </BaseModal>
    </section>
  </Page>
</template>

<script setup>
import {
  computed, onMounted, ref, inject,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

import OrderDetails from '@/components/OrderDetails.vue';
import Page from '@/components/Page.vue';
import BannerImageAndTitle from '@/components/banner/BannerImageAndTitle.vue';
import BaseButton from '@/components/common/BaseButton.vue';
import BaseModal from '@/components/common/BaseModal.vue';
import CardOrder from '@/components/common/Card/CardOrder.vue';
import { convertTotal } from '@/mixins/utils';

const store = useStore();
const { locale } = useI18n();
const { HOME } = inject('routeNames');

const selectedOrder = ref(null);
const showModal = computed(() => store.state.overlays.openModal === 'OrderDetailsModal');
const orderHistory = computed(() => store.state.order.orderHistory);

const handleModal = val => {
  selectedOrder.value = val;
  store.dispatch('overlays/setModal', 'OrderDetailsModal');
};

onMounted(() => store.dispatch('order/fetchOrderHistory', {}));

</script>

<style lang="scss" scoped>
.c-orderHistory {
  padding-bottom: 150px;

  .-topGap {
    margin-top: 3rem;
  }

  .-spaceAuto {
    margin: auto;
  }
}

.order-modal {
  &::v-deep(.c-modal__body) {
    display: block;
  }
}
</style>
